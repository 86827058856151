import React, { Component } from 'react'
import ActionDecision from './ActionDecision';
import ChallengeDecision from './ChallengeDecision';
import BlockChallengeDecision from './BlockChallengeDecision';
import PlayerBoard from './PlayerBoard';
import RevealDecision from './RevealDecision';
import BlockDecision from './BlockDecision';
import ChooseInfluence from './ChooseInfluence';
import ExchangeInfluences from './ExchangeInfluences';
import './CoupStyles.css';
import EventLog from './EventLog';
import ReactModal from 'react-modal';
import CheatSheetModal from '../CheatSheetModal';
import RulesModal from '../RulesModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCoins, faHeart } from '@fortawesome/free-solid-svg-icons';

export default class Coup extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             action: null,
             blockChallengeRes: null,
             players: [],
             playerIndex: null,
             currentPlayer: '',
             isChooseAction: false,
             revealingRes: null,
             blockingAction: null,
             isChoosingInfluence: false,
             exchangeInfluence: null,
             error: '',
             winner: '',
             playAgain: null,
             logs: [],
             isDead: false,
             waiting: true,
             disconnected: false
        }
        const bind = this;

        this.playAgainButton = <>
        <br></br>
        <button className="startGameButton" onClick={() => {
            this.props.socket.emit('g-playAgain');
        }}>Play Again</button>
        </>

        this.props.socket.on('disconnect', reason => {
            this.setState({ disconnected: true });
        })

        this.props.socket.on('g-gameOver', (winner) => {
            bind.setState({winner: `${winner} Wins!`})
            bind.setState({playAgain: bind.playAgainButton})
        })
        this.props.socket.on('g-updatePlayers', (players) => {
            bind.setState({playAgain: null})
            bind.setState({winner: null})
            players = players.filter(x => !x.isDead);
            let playerIndex = null;
            for(let i = 0; i < players.length; i++) {
                console.log(players[i].name, this.props.name)
                if(players[i].name === this.props.name) {
                    playerIndex = i;
                    break;
                }
            }
            if(playerIndex == null) {
                this.setState({ isDead: true })
            }else {
                this.setState({ isDead: false})
            }
            console.log(playerIndex)
            bind.setState({playerIndex, players});
            
        });
        this.props.socket.on('g-updateCurrentPlayer', (currentPlayer) => {
            console.log('currentPlayer: ', currentPlayer)
            bind.setState({ currentPlayer });
        });
        this.props.socket.on('g-addLog', (log) => {
            let splitLog=  log.split(' ');
            let coloredLog = [];
            coloredLog = splitLog.map((item, index) => {
                let found = null
                bind.state.players.forEach(player => {
                    if(item === player.name){
                        found = <b style={{color: player.color}}>{player.name} </b>;
                    }
                })
                if(found){
                    return found;
                }
                return <>{item+' '}</>
            })
            bind.state.logs = [...bind.state.logs, coloredLog]
            bind.setState({logs :bind.state.logs})
        })
        this.props.socket.on('g-chooseAction', () => {        
            bind.setState({ isChooseAction: true})
        });
        this.props.socket.on('g-openExchange', (drawTwo) => {
            let influences = [...bind.state.players[bind.state.playerIndex].influences, ...drawTwo];
            bind.setState({ exchangeInfluence: influences });
        })
        this.props.socket.on('g-openChallenge', (action) => {
            if(this.state.isDead) {
                return
            }
            if(action.source !== bind.props.name) {
               bind.setState({ action }) 
            } else {
                bind.setState({ action: null }) 
            }
        });
        this.props.socket.on('g-openBlockChallenge', (blockChallengeRes) => {
            if(this.state.isDead) {
                return
            }
            if(blockChallengeRes.counterAction.source !== bind.props.name) {
               bind.setState({ blockChallengeRes }) 
            } else {
                bind.setState({ blockChallengeRes: null }) 
            }
        });
        this.props.socket.on('g-openBlock', (action) => {
            if(this.state.isDead) {
                return
            }
            if(action.source !== bind.props.name) {
                bind.setState({ blockingAction: action })
             } else {
                 bind.setState({ blockingAction: null }) 
             }
        });
        this.props.socket.on('g-chooseReveal', (res) => {
            console.log(res)
            bind.setState({ revealingRes: res});
        });
        this.props.socket.on('g-chooseInfluence', () => {
            bind.setState({ isChoosingInfluence: true });
        });
        this.props.socket.on('g-closeChallenge', () => {
            bind.setState({ action: null });
        });
        this.props.socket.on('g-closeBlock', () => {
            bind.setState({ blockingAction: null });
        });
        this.props.socket.on('g-closeBlockChallenge', () => {
            bind.setState({ blockChallengeRes: null });
        });
    }

    cardColours = {
        duke: "#d949db",
        assassin: "#5c5775",
        captain: "#53caf5",
        ambassador: "#c0d72f",
        contessa: "#e31f26",
        misc: "#ababab",
    };

    cardDescriptions = {
        duke: `Duke can use <strong style="color: ${this.cardColours.duke}">Tax</strong> to take 3 coins from the treasury. Blocks <strong style="color: ${this.cardColours.misc}">Foreign Aid</strong>.`,
        assassin: `Assassin can use <strong style="color: ${this.cardColours.assassin}">Assassinate</strong>, paying 3 coins to kill off one Influence.`,
        captain: `Captain can use <strong style="color: ${this.cardColours.captain}">Steal</strong> to take 2 coins from another player. Blocks <strong style="color: ${this.cardColours.captain}">stealing</strong>.`,
        ambassador: `Ambassador can use <strong style="color: ${this.cardColours.ambassador}">Exchange</strong> to swap cards with the deck. Blocks <strong style="color: ${this.cardColours.captain}">stealing</strong>.`,
        contessa: `Contessa can block an <strong style="color: ${this.cardColours.assassin}">Assassination</strong>.`,
    };

    getUniqueInfluenceDescriptions = (influences) => {
        const uniqueInfluences = Array.from(
            new Set(influences.map(influence => influence.toString().toLowerCase()))
        );
        
        return (
            <div style={{ marginTop: "0.75em", marginBottom: "0.75em", width: "80vw" }}>
            {
                uniqueInfluences.map((influenceName, index) => (
                    <span key={index}>
                        <div
                        dangerouslySetInnerHTML={{
                            __html: this.cardDescriptions[influenceName],
                        }}
                        />
                    </span>
                    ))
            }
            </div>
        );
          
      }

    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    deductCoins = (amount) => {
        let res = {
            source: this.props.name,
            amount: amount
        }
        this.props.socket.emit('g-deductCoins', res);
    }

    doneAction = () => {
        this.setState({ 
            isChooseAction: false
        })
    }
    doneChallengeBlockingVote = () => {
        this.setState({ action: null }); //challemge
        this.setState({ blockChallengeRes: null}); //challenge a block
        this.setState({ blockingAction: null }); //block
    }
    closeOtherVotes = (voteType) => {
        if(voteType === 'challenge') {
            this.setState({ blockChallengeRes: null}); //challenge a block
            this.setState({ blockingAction: null }); //block
        }else if(voteType === 'block') {
            this.setState({ action: null }); //challemge
            this.setState({ blockChallengeRes: null}); //challenge a block
        }else if(voteType === 'challenge-block') {
            this.setState({ action: null }); //challemge
            this.setState({ blockingAction: null }); //block
        }
    }
    doneReveal = () => {
        this.setState({ revealingRes: null });
    }
    doneChooseInfluence = () => {
        this.setState({ isChoosingInfluence: false })
    }
    doneExchangeInfluence = () => {
        this.setState({ exchangeInfluence: null })
    }
    pass = () => {
        if(this.state.action != null) { //challengeDecision
            let res = {
                isChallenging: false,
                action: this.state.action
            }
            console.log(res)
            this.props.socket.emit('g-challengeDecision', res);
        }else if(this.state.blockChallengeRes != null) { //BlockChallengeDecision
            let res = {
                isChallenging: false
            }
            console.log(res)
            this.props.socket.emit('g-blockChallengeDecision', res);
        }else if(this.state.blockingAction !== null) { //BlockDecision
            const res = {
                action: this.state.blockingAction,
                isBlocking: false
            }
            console.log(res)
            this.props.socket.emit('g-blockDecision', res)
        }
        this.doneChallengeBlockingVote();
    }

    influenceColorMap = {
        duke: '#D55DC7',
        captain: '#80C6E5',
        assassin: '#2B2B2B',
        contessa: '#E35646',
        ambassador: '#B4CA1F'
    }
    
    render() {
        let actionDecision = null
        let currentPlayer = null
        let revealDecision = null
        let challengeDecision = null
        let blockChallengeDecision = null
        let chooseInfluenceDecision = null
        let blockDecision = null
        let influences = null
        let pass = null
        let coins = null
        let exchangeInfluences = null
        let playAgain = null
        let isWaiting = true
        let waiting = null
        if(this.state.isChooseAction && this.state.playerIndex != null) {
            isWaiting = false;
            actionDecision = <ActionDecision doneAction={this.doneAction} deductCoins={this.deductCoins} name={this.props.name} socket={this.props.socket} money={this.state.players[this.state.playerIndex].money} players={this.state.players}></ActionDecision>
        }
        if(this.state.currentPlayer) {
            currentPlayer = <p style={{ fontSize: "20px" }}><b>{this.state.currentPlayer}</b>'s turn</p>
        }
        if(this.state.revealingRes) {
            isWaiting = false;
            revealDecision = <RevealDecision doneReveal={this.doneReveal} name ={this.props.name} socket={this.props.socket} res={this.state.revealingRes} influences={this.state.players.filter(x => x.name === this.props.name)[0].influences}></RevealDecision>
        }
        if(this.state.isChoosingInfluence) {
            isWaiting = false;
            chooseInfluenceDecision = <ChooseInfluence doneChooseInfluence={this.doneChooseInfluence} name ={this.props.name} socket={this.props.socket} influences={this.state.players.filter(x => x.name === this.props.name)[0].influences}></ChooseInfluence>
        }
        if(this.state.action != null || this.state.blockChallengeRes != null || this.state.blockingAction !== null){
            pass = <button onClick={() => this.pass()}>Pass</button>
        }
        if(this.state.action != null) {
            isWaiting = false;
            challengeDecision = <ChallengeDecision closeOtherVotes={this.closeOtherVotes} doneChallengeVote={this.doneChallengeBlockingVote} name={this.props.name} action={this.state.action} socket={this.props.socket} ></ChallengeDecision>
        }
        if(this.state.exchangeInfluence) {
            isWaiting = false;
            exchangeInfluences = <ExchangeInfluences doneExchangeInfluence={this.doneExchangeInfluence} name={this.props.name} influences={this.state.exchangeInfluence} socket={this.props.socket}></ExchangeInfluences>
        }
        if(this.state.blockChallengeRes != null) {
            isWaiting = false;
            blockChallengeDecision = <BlockChallengeDecision closeOtherVotes={this.closeOtherVotes} doneBlockChallengeVote={this.doneChallengeBlockingVote} name={this.props.name} prevAction={this.state.blockChallengeRes.prevAction} counterAction={this.state.blockChallengeRes.counterAction} socket={this.props.socket} ></BlockChallengeDecision>
        }
        if(this.state.blockingAction !== null) {
            isWaiting = false;
            blockDecision = <BlockDecision closeOtherVotes={this.closeOtherVotes} doneBlockVote={this.doneChallengeBlockingVote} name={this.props.name} action={this.state.blockingAction} socket={this.props.socket} ></BlockDecision>
        }
        if(this.state.playerIndex != null && !this.state.isDead) {
            influences = <>
            <p style={{ margin: "0" }}>Your Influences:</p>
            <h2 style={{ marginTop: "0em" }}><strong dangerouslySetInnerHTML={{ __html: `${this.state.players[this.state.playerIndex].influences.map(influence => {
    const color = this.cardColours[influence.toString().toLowerCase()];
    return `<strong style="color: ${color} !important">${influence.toString().charAt(0).toUpperCase() + influence.toString().slice(1)}</strong>`;
  }).join(" and ")}` }}></strong></h2>
            {/* <p>Your Influences</p> */}
                {this.state.players[this.state.playerIndex].influences.map((influence, index) => {
                    return  <div key={index} className="InfluenceUnitContainer">
                                {/* <span className="circle" style={{backgroundColor: `${this.influenceColorMap[influence]}`}}></span> */}
                                <img
                                    src={`${influence}.png`}
                                    alt={influence}
                                    style={{
                                    width: "15vh",
                                    borderRadius: "10px",
                                    transform: `rotate(${
                                        this.state.players[this.state.playerIndex].influences.length === 2
                                        ? index === 0
                                            ? -5
                                            : 5
                                        : 0
                                    }deg)`,
                                    }}
                                />
                                <br></br>
                                {/* <h3>{influence.toString().charAt(0).toUpperCase() + influence.toString().slice(1)}</h3> */}
                            </div>
                    })
                }
                <br />
                <span>
                    <span class="money"><FontAwesomeIcon icon={faCoins} /> {this.state.players[this.state.playerIndex].money}</span>
                    {/* <span class="life"><FontAwesomeIcon icon={faHeart} /> {this.state.players[this.state.playerIndex].influences.length}</span> */}
                </span>
                {this.getUniqueInfluenceDescriptions(this.state.players[this.state.playerIndex].influences)}
            </>
            
            coins = <p>Coins: {this.state.players[this.state.playerIndex].money}</p>
        }
        if(isWaiting && !this.state.isDead) {
            waiting = <i><FontAwesomeIcon icon={faClock} /> Waiting for other players...</i>
        }
        if(this.state.disconnected) {
            return (
                <div className="GameContainer">
                    <div className="GameHeader">
                        <div className="PlayerInfo">
                            <p>You are: {this.props.name}</p>
                            {coins}
                        </div>
                        <RulesModal/>
                        <CheatSheetModal/>
                    </div>
                    <p>You have been disconnected :c</p>
                    <p>Please recreate the game.</p>
                    <p>Sorry for the inconvenience (シ_ _)シ</p>
                </div>
            )
        }
        return (
            <div className="GameContainer">
                <div className="GameHeader">
                    <img src="logo.png" alt="logo" className="PlayerInfo"/>
                    <div className="PlayerInfo">
                        <p>You are: {this.props.name}</p>
                        {coins}
                    </div>
                    <div className="CurrentPlayer">
                        {currentPlayer}
                    </div>
                    <RulesModal/>
                    <CheatSheetModal/>
                    <EventLog logs={this.state.logs}></EventLog>
                </div>
                <div className="InfluenceSection">
                    {influences}
                </div>
                <PlayerBoard players={this.state.players}></PlayerBoard>
                <div className="DecisionsSection">
                    {waiting}
                    {revealDecision}
                    {chooseInfluenceDecision}
                    {actionDecision}
                    {exchangeInfluences}
                    {challengeDecision}
                    {blockChallengeDecision}
                    {blockDecision}
                    {pass}
                    {playAgain}
                </div>
                <b>{this.state.winner}</b>
                {this.state.playAgain}
            </div>
        )
    }
}
