import React from 'react'
import './PlayerBoardStyles.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSkull,
  faMagnifyingGlass,
  faCoins,
} from "@fortawesome/free-solid-svg-icons";

export default function PlayerBoard(props) {
    let boardItems = null
    if(props.players.length > 1) {
        boardItems = props.players.map((player, index) =>
            <div className="PlayerBoardItemContainer" key={index}>
                <span className="PlayerBoardItem" style={{ backgroundColor: `${player.color}` }} key={index}>
                    <h2>{player.name}</h2>
                    <p>Coins: {player.money}</p>
                    <p>Influences: {player.influences.length}</p>
                    {/* <p>{player.influences.join(', ')}</p> */}
                    {player.money >= 7 && <FontAwesomeIcon icon={faSkull} className="exclamation" />}
                </span>
                {/* {player.money >= 7 && <h1 className="exclamation">!</h1>} */}
            </div>
            
        );
    }
    return (
        <div className="PlayerBoardContainer" style={{textAlign: "center"}}>
            {boardItems}
        </div>
    )
  }



