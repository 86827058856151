import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import ReactModal from 'react-modal';

export default class RulesModal extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            showRulesModal: false,
        }
    }

    handleOpenRulesModal = () => {
        this.setState({ showRulesModal: true });
    }

    handleCloseRulesModal = () => {
        this.setState({ showRulesModal: false });
    }
    
    render() {
        let modal = <ReactModal 
        isOpen={this.state.showRulesModal}
        contentLabel="Minimal Modal Example"
        onRequestClose={this.handleCloseRulesModal}
        shouldCloseOnOverlayClick={true}
    >
    <div className="CloseModalButtonContainer">
        <button className="CloseModalButton" onClick={this.handleCloseRulesModal}>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
                <g id="more_info" data-name="more info" transform="translate(-39 -377)">
                    <g id="Ellipse_1" data-name="Ellipse 1" class="cls-5" transform="translate(39 377)">
                    <circle class="cls-7" cx="10.5" cy="10.5" r="10.5"/>
                    <circle class="cls-8" cx="10.5" cy="10.5" r="10"/>
                    </g>
                    <text id="x" class="cls-6" transform="translate(46 391)"><tspan x="0" y="0">x</tspan></text>
                </g>
            </svg>
        </button>
    </div>
   
    <div className="RulesContainer">
        <div className="RulesContent">
            <h2>Rules</h2>
            <p>The goal of Coup is to be the last player standing. Use your Influences (cards) to get money, form alliances, and attack other players.</p>
            <p>On your turn, you can use one action. You can perform one of your Influence's abilities, or lie about your Influences and try to use a different one. The other players can choose to <strong>block</strong> or <strong>challenge</strong> your action.</p>
            <ul>
                <li>            <p><b>Challenge</b>: AKA "I think you're lying". If somebody challenges you, you must reveal the Influence that you claimed to have. If you can, the person who challenged you loses an Influence. If you can't, you lose an Influence.</p>
                </li>
                <li>
                <p><b>Block</b>: Some actions can be blocked by certain Influences. If you are blocked, you can't perform your action. However, you can <strong>challenge</strong> the person who blocked you, and they must reveal the Influence they used to block you. If they can't, they lose an Influence. If they can, you lose an Influence.
                </p>
                </li>
            </ul>

            <p>
                If a player loses all their influences, they are out of the game. 
            </p>
            <h2>Influences</h2>
            <ul>
                <li>            <p>Captain can <b id="captain-color">Steal</b>: Steal 2 coins from a target. Blockable by <hl id="captain-color"><strong>Captain</strong></hl> or <hl id="ambassador-color"><strong>Ambassador</strong></hl>. Can block <hl id="captain-color"><strong>steal</strong></hl>.</p>
                </li>
                <li>            <p>Assassin can <b id="assassin-color">Assassinate</b>: Pay 3 coins to choose a target to assassinate (target loses an influence). Blockable by <hl id="contessa-color"><strong>Contessa</strong></hl>.</p>
                </li>
                <li>            <p>Duke can <b id="duke-color">Tax</b>: Collect 3 coins from the treasury. Can block <strong>Foreign Aid</strong>. Valuable early-game to get quick money for attacks.</p>
                </li>
                <li>            <p>Ambassador can <b id="ambassador-color">Exchange</b>: Draw 2 Influences, then return any 2 Influences to the deck. Can block <hl id="captain-color"><strong>steal</strong></hl>.</p>
                </li>
                <li>            <p>Contessa can <b id="contessa-color">Block Assassination</b>: Can block <b id="assassin-color">assassinations</b>. Valuable late-game when there a lot of killings.</p>
                </li>
                
                
                
                
                
            </ul>
            <h2>Other Actions (no specific Influence required)</h2>
            <ul>
                <li>
                <p><b>Income</b>: Collect 1 coin.</p>

                </li>
                <li>
                <p><b>Foreign Aid</b>: Collect 2. Blockable by <hl id="duke-color"><strong>Duke</strong></hl>.</p>

                </li>
                <li>
                <p><b>Coup</b>: Pay 7 coins and choose a target to lose an influence. If a player starts their turn with 10 or more coins, they must Coup. Not blockable.</p>

                </li>
            </ul>
        </div>
    </div>
    </ReactModal>
        if(this.props.home) {
            return(
                <>
                    <div className="HomeRules" onClick={this.handleOpenRulesModal}>
                        <p>Rules </p>  
                        <svg className="InfoIcon"xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 21 22">
                            <g id="more_info" data-name="more info" transform="translate(-39 -377)">
                                <g id="Ellipse_1" data-name="Ellipse 1" className="cls-1" transform="translate(39 377)">
                                <circle className="cls-3" cx="10.5" cy="10.5" r="10.5"/>
                                <circle className="cls-4" cx="10.5" cy="10.5" r="10"/>
                                </g>
                                <text id="i" className="cls-2" transform="translate(48 393)"><tspan x="0" y="0">i</tspan></text>
                            </g>
                        </svg>
                    </div>
                    {modal}
                </>
            )
        }
        return (
            <>
            <div className="Rules" onClick={this.handleOpenRulesModal}>
                <p><FontAwesomeIcon icon={faCircleInfo} /> Rules</p>  
            </div>
            {modal}
            </>
        )
    }
}
